import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultAuthGuardGuard } from './guard/default-auth-guard.guard';
import { AdultAuthGuardGuard } from './guard/adult-auth-guard.guard';

const routes: Routes = [


  {
    path: '',
    loadChildren: 'src/app/default/default.module#DefaultModule',
    canActivate: [DefaultAuthGuardGuard]
  },
  {
    path: 'account',
    loadChildren: 'src/app/adult/adult.module#AdultModule',
    canActivate: [AdultAuthGuardGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
