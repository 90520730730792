export const config: any = {
	// 'api_url':"http://devsecure.mysafetynet.info/api/v1/",
	'api_url': "https://admin.adultsafetynet.net/api/v1/",
	'local_api_url': "http://localhost/mysafetynet/mysafety-adult-backend/public/api/v1/",
	'live_api_url': "https://myadultsafetynet.developmentstagingserver.com/backend/api/v1/",

	// 'api_url':"http://localhost/citrusbug/laravel/mysafety-adult/public/api/v1/",
	// 'PUBLISHABLE_KEY':'pk_test_7Barys4nP4dexRYSLTjN3gnI',
	'PUBLISHABLE_KEY_client': 'pk_test_iJLRAJToTtTWKSicsMj3YIhw',
	'PUBLISHABLE_KEY': 'pk_test_51M517XGvYFskJjpNrvh1Cr6bdVu4i5PgDOy6mMDzZ6juf2GcN7wZdKlRofpWbXYUSo2GVKqRJSafLFJc9u8iPxXj00iP4HYyAJ',
	'parental_permission_form': 'https://admin.adultsafetynet.net/files/parentalpermissionform.pdf',
	'free_trial_duration': '1 Year'

};

export const homepage: any = {
	'header_title_h1': "MYSAFETYNET NOW BRINGS YOU THE FUTURE OF ONLINE CHILD PROTECTION",
	'header_title_h2': "Let us take you from the ERA of algorithms and information, to the ERA of identification and validation",
};


export const features: any = {
	'list_a': {
		'title': 'Monitor',
		'desc': 'Protect your child without spying or blocking'
	},
	'list_b': {
		'title': 'Risk Detection',
		'desc': 'Online activity constantly assessed for threats'
	},
	'list_c': {
		'title': 'Parent Alert',
		'desc': 'Real-time threat alerts for parents'
	},
	'list_d': {
		'title': 'Education',
		'desc': 'Encouraging open discussions between parents and children'
	},
	'title': "Simple, User-Friendly iOS And Android Apps",
	'description': "Our dual-layered protection and validation system will help to protect your child from online predators throughout their online travels. Download the app today!"

};


export const completeProtection: any = {
	'title_a': "Backed By Research",
	'description_a1': "According to Pamela Wisniewski, an Assistant Professor of Engineering and Computer Science,as well as an expert of adolescent online safety, “The fear that teens will fall victim to unthinkable dangers persists, and our research is challenging the current solutions for	protecting teens online by tightening the reins. Instead, we suggest empowering teens to be	agents of their own online safety.”",
	'description_a2': "While providing dual-layered protection for your child, MySafetyNet strives to educate parents and children alike on responsible internet use. Traditional methods of online protection have proven to be inefficient, which is why the MySafetyNet system allows children to have online freedom while allowing parents to monitor who their child is interacting with.",
	'title_b': "Complete Protection",
	'description_b1': "In a world reliant on online learning and communication, parents can never be too cautious about their child’s online activities. Did you know that, according to the Crimes Against Children Research Center, 1 in every 5 U.S. teenagers who regularly use the internet receives unwanted sexual solicitation? These types of solicitations often request to engage in inappropriate activities."

};

export const howitswork: any = {

	'list_a': {
		'title': 'Sign Up',
		'desc': "Sign up for an account online and add your family’s details."
	},
	'list_b': {
		'title': 'Download',
		'desc': 'Download the app and log in using your unique account details.'
	},
	'list_c': {
		'title': 'Add Devices',
		'desc': 'Set up and link	your family	devices to your	account.'
	},
	'list_d': {
		'title': 'Protect',
		'desc': "Your family’s supported	devices are now	ready to be	protected from online threats."
	},
	'title': "How The App Works",
	'description': "You can set up protection for your family in under ten minutes!"

};

export const pricingplans: any = {
	'title': "Easy Pricing Plans",
	'description': "Select from our affordable monthly and annual plans below to get started today!"

};
export const managestripeaccount: any = {
	'title': "Payment Methods",
	'description': ""

};

export const childsubscription: any = {
	'step_list': {
		'title': "Subscriptions for child",
		'description': ""
	},
	'step_upgrade': {
		'title': "Upgrade Subscription",
		'description': "On upgrade plan,current subscription will be stoped from now and new subscription will be start."
	},
	'step_unsubscribe': {
		'title': "Unsubscribe From Current Plan",
		'description': "On Unsubscribe your child will be inactive, You can re-active by upgrade subscription"
	}

};

export const latest_news: any = {
	'title': "Latest news from us",
	'description': "Read latest technology news from our blog."

};

export const contactus: any = {
	'title': "CONTACT MYSAFETYNET",
	'description': ""

};

export const complaint: any = {
	'title': "anonymous complaint",
	'description': ""

};

export const myorders: any = {
	'title': "Orders",
	'description': ""

};

export const myaccount: any = {
	'title': "My Account",
	'': ""

};

export const mychildlist: any = {
	'title': "Kid / Child",
	'description': ""

};
export const addchild: any = {
	'title': "Add child",
	'description_step1': "Child's baisic information",
	'description_step2': "Please select pricing plan to proceed",
	'description_step3': "Payment information",
	'description_first_charge_info': "One-time enrollment, validation, and processing fee of $11.99 for all first-time user	subscriptions.",
	'one_time_charge': 10,
	'free_package_limit': 1,
	'free_package_limit_unit': "year",
	'free_package_till': "August 31st 2019"

};

export const parentnotification: any = {
	'title': "NOTIFICATION",
	'description': "",
	'description_no_request': "You have no notifications."
};




export const childprofile: any = {
	'title': "Profile",
	'description': ""

};

export const childeditprofile: any = {
	'title': "Edit Profile",
	'description': ""

};

export const badge: any = {
	'title': "Badge",
	'description_step1': "Enter child's badge id to connect.",
	'description_step2': "Please select pricing plan to proceed",
	'description_step3': "Payment information"

};

export const confirmrquest: any = {
	'title': "CONFIRM REQUEST",
	'description_no_request': "You have no badge request.",
	'description': ""

};

export const childnotification: any = {
	'title': "NOTIFICATION",
	'description': "",
	'description_no_request': "You have no notifications."
};
