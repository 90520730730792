import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdultAuthGuardGuard implements CanActivate {

  constructor(private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

	let authuser = JSON.parse(localStorage.getItem("authuser"));
    if(authuser && authuser.user_type == 'CU'){

    }else{
     // this.router.navigate(['signin']);
     // return false;
    }

    return true;
  }

}
