// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "8e3638820945bcc7b9fdac98f66a7142a462ad13",
		authDomain: "mysafetynet-f1902.firebaseapp.com",
		databaseURL: "https://mysafetynet-f1902.firebaseio.com/",
		projectId: "mysafetynet-f1902",
		storageBucket: "lfk-parent.appspot.com",
		messagingSenderId: "859740767333"
  },
  gaConfig: {
    trackingId: 'AW-763140610',
    debug: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
