import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Headers, RequestOptions, Response, Http } from '@angular/http';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';

import { CommonEventsService } from "./common-events.service";
import { config } from "./app-data";

const API_URL = config.api_url

@Injectable()
export class AuthApiService {

  headers: any;
  options: RequestOptions;


  constructor(public http: HttpClient,private subscription: CommonEventsService) {
    let headers = new  HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json','devicetype':'web'});
    this.headers = headers;
  }
  // Search for github users
  submit(modal:string,myData: any): Observable<any> {
    return this.http.post(API_URL+modal, myData, { headers: this.headers })
      .map(this.extractData)
      .catch(this.handleError);
  }
  getData(modal:string,myData: any): Observable<any> {
    return this.http.get(API_URL+modal,{ headers: this.headers })
      .map(this.extractData)
      .catch(this.handleError);
  }


  // Search for github users
  submitAuth(modal:string,myData: any): Observable<any> {
    return this.http.post(API_URL+modal, myData, { headers: this.getAuthHeader() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  submitAuthPut(modal:string,myData: any): Observable<any> {
    return this.http.put(API_URL+modal, myData, { headers: this.getAuthHeader() })
      .map(this.extractData)
      .catch(this.handleError);
  }
  
  formObjectSubmit(modal:string,myData: any): Observable<any> {
    let header = new  HttpHeaders({ 'devicetype':'web'});
    return this.http.post(API_URL+modal, myData,{ headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }
  formObjectSubmitAuth(modal:string,myData: any): Observable<any> {
	let authuser: any = JSON.parse(localStorage.getItem("authuser"));
    
    let token = authuser.token;
	let header = new  HttpHeaders({ 'devicetype':'web','Authorization':'bearer '+token});
   
    
    return this.http.post(API_URL+modal, myData,{ headers: header })
      .map(this.extractData)
      .catch(this.handleError);
  }

  doLogout(modal:string,myData: any): Observable<any> {
    return this.http.post(API_URL+modal,{ headers: this.getAuthHeader() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  getAuthData(modal:string,myData: any): Observable<any> {
    return this.http.get(API_URL+modal,{ headers: this.getAuthHeader() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  getAuthHeader(){
    let token = "";

    let authuser: any = JSON.parse(localStorage.getItem("authuser"));
    if(authuser && authuser.token != ''){
      token = authuser.token;
    }
    let header = new  HttpHeaders({ 'devicetype':'web','Content-Type': 'application/json', 'Accept': 'application/json','Authorization':'bearer '+token});
    header.append('Content-Type', 'multipart/form-data');
    header.append('devicetype', 'web');

    return header;
  }



 private extractData(res: Response) {
  let result = res;
  return result || {};
}

  private handleError(error: any) {

    return Observable.throw(error);
  }






}
